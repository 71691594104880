@use "./utils/mixin.scss" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background: orange;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  color: #eee;
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-underline-offset: 2px;
  cursor: inherit;

  @include min-screen() {
    &:hover,
    &:focus {
      color: #e88b00;
    }
  }
}

small {
  font-size: 100%;
}
