@use "../utils/mixin.scss" as *;

/* =============================================================================
 * ハンバーガーボタン
 * ========================================================================== */
.btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 50px;
  height: 50px;
  padding: 0;
  background: transparent;
  border: 0;

  &::before,
  &::after,
  &__label::before,
  &__label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    margin: auto;
    content: "";
    background: #fff;
  }

  &::before,
  &::after {
    transition: .2s opacity ease-out;
  }

  &::before {
    transform: translateY(-10px);
  }

  &::after {
    transform: translateY(10px);
  }

  &__label {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;

    &::before,
    &::after {
      transition: .2s transform ease-out;
    }
  }

  &[aria-expanded="true"] {
    z-index: 4;

    &::before,
    &::after {
      opacity: 0;
    }

    .btn__label::before {
      transform: rotate(45deg);
    }

    .btn__label::after {
      transform: rotate(-45deg);
    }
  }
}

/* =============================================================================
 * グローバルナビゲーション
 * ========================================================================== */
.nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  height: 100%;
  overflow: hidden;
  text-align: right;

  &__inner {
    width: 270px;
    height: 100%;
    padding: 70px 0 0;
    visibility: visible;
    background: rgba(0, 0, 0, .8);
    transition: .2s opacity ease-out, .2s visibility ease-out, .2s transform ease-out;
    transform: translateX(0);
  }

  &__scroll {
    height: 100%;
    padding: 15px 17px 30px 20px;
    overflow: auto;
  }

  &[hidden] {
    display: block;
    pointer-events: none;

    .nav__inner {
      visibility: hidden;
      opacity: 0;

      transform: translateX(100%);
    }
  }

  &__hdg {
    padding: 25px 0 0;
    margin: 24px 0 10px;
    font-size: 18px;
    border-top: 1px solid #555;
  }

  &__ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__a {
    display: block;
    padding: 5px 8px;
    line-height: 1;
    color: #fff;
    text-decoration: none;
  }

  &__title {
    line-height: 1.6;
  }

  &__url {
    font-size: 10px;
    opacity: .5;
  }

  @include min-screen() {
    &__a {
      display: block;

      &[href] {
        transition: .2s background-color ease-out;

        &:hover,
        &:focus {
          color: #fff;
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
  }
}
