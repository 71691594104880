@use "../utils/mixin.scss" as *;

.main {
  position: relative;
  grid-row: 2 / 3;
  max-width: 640px;
  padding: 74px 20px 36px;
  margin: 0 auto;
  overflow: hidden;
  font-family: serif;
  text-align: center;

  @include min-screen() {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.h1 {
  &__logo {
    display: block;
    width: 450px;
    max-width: 40vw;
    height: auto;
    margin: 0 auto 40px;
    opacity: 0;
    transition: 2s opacity ease-out .3s;
    aspect-ratio: 1 / 1;

    &[data-state="loaded"] {
      opacity: 1;
    }
  }

  &__txt {
    font-size: 30px;
  }
}
