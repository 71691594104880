@use "../utils/mixin.scss" as *;

.wrap {
  position: sticky;
  bottom: 0;
  grid-row: 3 / 4;
  padding: 0 20px;
  text-align: right;
}

.material {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 40px;
  font-size: 14px;
  text-align: left;
  background: rgba(0, 0, 0, .9);
  transition: .2s opacity ease-out, .2s visibility ease-out;

  &__inner {
    width: 100%;
    max-width: 400px;
    padding: 20px 20px 24px;
    color: #333;
    background: rgba(255, 255, 255, .8);
  }

  &__hdg {
    margin: 0 0 8px;
    font-size: 18px;
  }

  &__p {
    margin: 0;
  }

  &__ul {
    padding: 0;
    margin: 12px 0 0;
  }

  &__li {
    list-style-position: inside;
  }

  &[hidden] {
    visibility: hidden;
    opacity: 0;
  }
}

.copyright {
  position: relative;
  z-index: 2;
  font-size: 12px;

  a {
    text-underline-offset: auto;
    cursor: pointer;
  }
}

.btn {
  padding: 0;
  color: #fff;
  text-decoration: underline;
  background: transparent;
  border: 0;

  @include min-screen() {
    &:hover,
    &:focus {
      color: #e88b00;
    }
  }
}
